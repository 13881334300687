export interface HubRuntimeApplicationDescriptor {
  id: string;
  applicationName: string;
  environmentId: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  flow: RuntimeFlow;
  projectName: string;
  projectTag?: number;
  image: string;
  deployedUrl: string;
  openapiPath?: string;
  healthPath: string;
  minReplicas: number;
  maxReplicas: number;
  lastDeploymentStatus: RuntimeStateStatus;
  status?: HubRuntimeApplicationStatus;
}

export interface HubRuntimeApplicationMetrics {
  openApiUrl: string;
  usage: {
    memory: number;
    cpu: number;
  };
  metrics: HubRuntimeApplicationEndpointsMetrics[];
}

export type HubRuntimeApplication = HubRuntimeApplicationDescriptor & HubRuntimeApplicationMetrics;

export interface HubRuntimeApplicationEndpointsMetrics {
  method: string;
  path: string;
  responseTime: number;
  consumption: number;
}

export interface HubRuntimeEnvironments {
  id: string;
  name: string;
  isDefault: boolean;
  description: string | null;
  invokeUrl: string;
  healthRequireApiKey: boolean;
  openapiRequireApiKey: boolean;
  apiRequireApiKey: boolean;
  metricsEnabled: boolean;
}

export interface HubRuntimeHealthCheckResponse {
  status: HubRuntimeApplicationStatus;
}

export enum HubRuntimeEnvironmentType {
  TEST = 'TEST',
  PROD = 'PROD',
}

export enum HubRuntimeApplicationStatus {
  UP = 'Running',
  PENDING = 'Pending',
  DOWN = 'Stopped',
}

export interface HubRuntimeEnvironmentMetrics {
  api: HubRuntimeGatewayMetrics;
  healthCheck: HubRuntimeGatewayMetrics;
  openApi: HubRuntimeGatewayMetrics;
}

export interface HubRuntimeGatewayMetrics {
  apiCallCount: Record<string, number>;
  apiCallLatency: Record<string, number>;
  totalCalls: number;
  avgLatency: number;
}

export enum HubRuntimeTabs {
  DASHBOARD = 'dashboard',
  MONITORING = 'monitoring',
}

export enum DashboardType {
  METRICS = 'ProjectDashboard',
  LOGS = 'Logs',
}

export enum RuntimeFlow {
  AGENT = 'AGENT',
  NOCODE = 'NOCODE',
}

export enum RuntimeStateStatus {
  PENDING = 'PENDING',
  EXECUTING = 'EXECUTING',
  WAITING = 'WAITING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
